import React from 'react';
import {
  NotificationModal,
  SignTransactionsModals,
  TransactionsToastList
} from '@multiversx/sdk-dapp/UI';
import { DappProvider } from '@multiversx/sdk-dapp/wrappers';
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate
} from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Layout from 'components/Layout';
import UpdateNotification from 'components/Notification';
import config from 'config';
import MaintenancePage from 'pages/MaintenancePage';
import PageNotFound from 'pages/PageNotFound';
import { appStateSelector } from 'recoil/selectors/appStateSelector';
import routes, { routeNames } from 'routes';

import './assets/sass/theme.scss';

const App = () => {
  const appState = useRecoilValue(appStateSelector);

  return appState?.state === 'MAINTENANCE' ? (
    <Router>
      <Layout showNavbar={false}>
        <Routes>
          <Route path='*' element={<MaintenancePage />} />
        </Routes>
      </Layout>
    </Router>
  ) : (
    <div>
      <UpdateNotification />
      <Router>
        <DappProvider
          environment={config.environment}
          dappConfig={{
            shouldUseWebViewProvider: true
          }}
          customNetworkConfig={{
            name: 'customConfig',
            apiTimeout: 120000,
            walletConnectV2ProjectId: config.walletConnectV2ProjectId
          }}
        >
          <Layout>
            <TransactionsToastList />
            <NotificationModal />
            <SignTransactionsModals className='custom-class-for-modals' />
            <Routes>
              {routes.map((route: any, index: number) => (
                <Route
                  path={route.path}
                  key={'route-key-' + index}
                  element={<route.component />}
                />
              ))}
              <Route
                path={routeNames.unlock}
                element={<Navigate replace to={routeNames.home} />}
              />
              <Route path='*' element={<PageNotFound />} />
            </Routes>
          </Layout>
        </DappProvider>
      </Router>
    </div>
  );
};

export default App;
