import React from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transaction } from '@multiversx/sdk-core/out';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { Modal } from 'react-bootstrap';
import * as apiRequests from 'apiRequests';
import { Position } from 'apiRequests/Farm';
import { InputTokenModel, StakingArgs } from 'apiRequests/staking.args';
import { NumberUtils } from 'helpers/number.utils';

interface ClaimRewardsModalProps {
  show: boolean;
  onHide: () => void;
  processTransactions: (transactions: Transaction[], method: string) => void;
  type: ClaimType;
  positions: Position[];
}

export enum ClaimType {
  HARVEST,
  REINVEST
}

const ClaimRewardsModal = ({
  show,
  onHide,
  processTransactions,
  type,
  positions
}: ClaimRewardsModalProps) => {
  const { address } = useGetAccountInfo();

  const handleClaim = async () => {
    try {
      const tokens = positions.map(
        (position) =>
          new InputTokenModel({
            identifier: position.farmToken?.collection,
            nonce: position.farmToken?.nonce,
            amount: position.farmToken?.balance.toString()
          })
      );

      const args = new StakingArgs({ tokens });
      const apiMethod =
        type === ClaimType.HARVEST
          ? apiRequests.getHarvestTransactions(address, args)
          : apiRequests.getReinvestTransactions(address, args);
      const transactions = await apiMethod;

      processTransactions(
        transactions,
        type === ClaimType.HARVEST ? 'harvest' : 'reinvest'
      );
    } catch (error) {
      throw error;
    }

    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header className='align-items-center'>
        <Modal.Title>
          {type == ClaimType.HARVEST ? 'Harvest Rewards' : 'Reinvest Rewards'}
        </Modal.Title>
        <span className='btn btn-primary btn-xs' onClick={onHide}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex flex-column align-items-center'>
          {positions.map((position, key) => {
            return (
              <div key={key} className='pt-4 textblock'>
                <img
                  src={position.rewardToken?.icon}
                  className='p-0 token-image'
                  style={{ width: '48px', height: '48px' }}
                />
                {type === ClaimType.HARVEST && '+'}
                {NumberUtils.toDenominatedString(
                  position.rewardToken?.balance ?? new BigNumber(0),
                  position.rewardToken?.decimals ?? 6,
                  4
                )}{' '}
                {position.rewardToken?.name}
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-primary btn-sm w-100' onClick={handleClaim}>
          {type === ClaimType.HARVEST ? 'Harvest' : 'Reinvest'}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClaimRewardsModal;
