import React, { useState } from 'react';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AccountModal from 'components/AccountModal';

const AccountBtn = () => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <button className='btn btn-sm' onClick={handleShowModal}>
        <FontAwesomeIcon icon={faUser} className='mr-2' />
        My Account
      </button>

      <AccountModal show={showModal} onHide={handleCloseModal} />
    </>
  );
};

export default AccountBtn;
