import { useEffect, useState } from 'react';
import axios from 'axios';
import config from 'config';

let currentVersion: string | undefined = undefined;

const useCheckVersion = () => {
  const [updateAvailable, setUpdateAvailable] = useState(false);

  const checkVersion = async () => {
    try {
      const { data: latestVersion } = await axios.get(
        `/version.json?${Date.now()}`
      );

      if (currentVersion && latestVersion) {
        setUpdateAvailable(currentVersion !== latestVersion);
      } else {
        currentVersion = latestVersion;
      }
    } catch {
      console.error('Unable to check new dapp version');
    }
  };

  useEffect(() => {
    checkVersion();

    const interval = setInterval(() => {
      if (!document.hidden) {
        checkVersion();
      }
    }, config.UPDATE_REFRESH_RATE);

    return () => {
      clearInterval(interval);
    };
  }, [updateAvailable]);

  return updateAvailable;
};

export { useCheckVersion };
