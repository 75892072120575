import { Transaction } from '@multiversx/sdk-core/out';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import BigNumber from 'bignumber.js';
import * as apiRequests from 'apiRequests';
import config from 'config';
import { InputTokenModel } from './staking.args';

interface TransactionResponse {
  success: boolean;
  error: string;
  sessionId: string | null;
}

export const buyTokens = async (
  egld: BigNumber
): Promise<{
  success: boolean;
  error: string;
  sessionId: string | null;
}> => {
  try {
    const transaction = {
      value: egld.toString(),
      data: 'swap',
      receiver: config.SWAP_CONTRACT_ADDRESS,
      gasLimit: 20000000,
      chainID: config.chainID
    };

    await refreshAccount();

    const { sessionId, error } = await sendTransactions({
      transactions: transaction,
      transactionsDisplayInfo: {
        processingMessage: 'Processing swap transaction',
        errorMessage: 'An error has occurred during swap',
        successMessage: 'Swap transaction successful'
      },
      redirectAfterSign: false,
      minGasLimit: '20000000'
    });

    return { success: error !== undefined, error: error ?? '', sessionId };
  } catch (error: any) {
    console.error(error);
    return { success: false, error: error.message, sessionId: null };
  }
};

export const mergeTokens = async (
  sender: string,
  tokens: any[]
): Promise<TransactionResponse> => {
  try {
    const transaction = await apiRequests.getMergeTokenTransaction(
      sender,
      tokens.map(
        (token) =>
          new InputTokenModel({
            identifier: token.tokenID,
            nonce: token.nonce,
            amount: token.amount
          })
      )
    );
    return await sendAndSignTransactions([transaction], 'merge');
  } catch (error: any) {
    console.error(error);
    return { success: false, error: error.message, sessionId: null };
  }
};

export const unlockToken = async (
  sender: string,
  token: any
): Promise<TransactionResponse> => {
  try {
    const transaction = await apiRequests.getUnlockTokenTransaction(
      sender,
      new InputTokenModel({
        identifier: token.tokenID,
        nonce: token.nonce,
        amount: token.amount
      })
    );

    return await sendAndSignTransactions([transaction], 'unlock');
  } catch (error: any) {
    console.error(error);
    return { success: false, error: error.message, sessionId: null };
  }
};

export const sendAndSignTransactions = async (
  transactions: Transaction[],
  transactionName: string
): Promise<{
  success: boolean;
  error: string;
  sessionId: string | null;
}> => {
  try {
    const { sessionId, error } = await sendTransactions({
      transactions: transactions,
      transactionsDisplayInfo: {
        processingMessage: `Processing ${transactionName} transaction`,
        errorMessage: `An error has occurred during ${transactionName}`,
        successMessage: `${transactionName} transaction successful`
      },
      redirectAfterSign: false,
      minGasLimit: '20000000'
    });

    await refreshAccount();

    return { success: error !== undefined, error: error ?? '', sessionId };
  } catch (error: any) {
    console.error(error);
    return { success: false, error: error.message, sessionId: null };
  }
};
