import React, { useEffect, useMemo, useRef, useState } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { Collapse, Modal } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { LockedToken, UnlockMilestone } from 'apiRequests/AccountDetails';
import { Farm } from 'apiRequests/Farm';
import { InputTokenModel, StakingArgs } from 'apiRequests/staking.args';
import { ReactComponent as ExpandLess } from 'assets/img/expand_less.svg';
import { ReactComponent as ExpandMore } from 'assets/img/expand_more.svg';
import { ReactComponent as LockIcon } from 'assets/img/lock_icon.svg';
import config from 'config';
import { calcUnlockDateText } from 'helpers/epochToDateHelpers';
import { NumberUtils } from 'helpers/number.utils';
import { accountDetailsSelector } from 'recoil/selectors/accountDetailsSelector';
import { appStatsSelector } from 'recoil/selectors/appStatsSelector';
import { getStakeValueFromInput } from '../helpers';
import StakeTokenAmountComponent from '../TokenAmount';
import TooltipSlider from '../TooltipSlider';

interface StakeModalProps {
  show: boolean;
  onHide: () => void;
  onStake: (args: StakingArgs) => void;
  farm: Farm;
}

const StakeModal = ({ show, onHide, onStake, farm }: StakeModalProps) => {
  const { address } = useGetAccountInfo();
  const accountDetails = useRecoilValue(accountDetailsSelector(address));
  const [selectedLockedToken, setSelectedLockedToken] = useState<
    LockedToken | undefined
  >(undefined);
  const [optionsExpanded, setOptionsExpanded] = useState(false);
  const prevOptionsExpandedRef = useRef<boolean>();
  const [lockRewards, setLockRewards] = useState(true);
  const [selectedTokenBalance, setSelectedTokenBalance] = useState<BigNumber>(
    new BigNumber(0)
  );
  const [inputValue, setInputValue] = useState('');
  const [amountPercent, setAmountPercent] = useState(0);
  const [amountValue, setAmountValue] = useState<BigNumber | undefined>(
    undefined
  );
  const [errorMessage, setErrorMessage] = useState('');

  const lockRewardsValue = useMemo(() => {
    if (farm.apr && farm.lockedApr) {
      return lockRewards;
    }

    if (farm.apr) {
      return false;
    }

    if (farm.lockedApr) {
      return true;
    }

    return true;
  }, [lockRewards, farm]);

  const resetState = () => {
    resetAmount();
    setSelectedLockedToken(undefined);
    setOptionsExpanded(false);
    setLockRewards(true);
  };

  useEffect(() => {
    const { amount, percentage, error } = getStakeValueFromInput(
      inputValue,
      farm,
      selectedTokenBalance
    );

    setAmountValue(amount);
    setAmountPercent(percentage);
    setErrorMessage(error);
  }, [inputValue]);

  useEffect(() => {
    prevOptionsExpandedRef.current = optionsExpanded;
  }, [optionsExpanded]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    if (farm.farmingToken?.identifier === config.LOCKED_UPARK.identifier) {
      if ((accountDetails?.lockedTokens.length ?? 0) > 0) {
        setSelectedTokenBalance(
          new BigNumber(accountDetails?.lockedTokens[0]?.balance ?? 0)
        );
        setSelectedLockedToken(accountDetails?.lockedTokens[0]);
      } else {
        setSelectedTokenBalance(new BigNumber(0));
      }
    } else {
      setSelectedTokenBalance(new BigNumber(accountDetails?.uparkBalance ?? 0));
    }

    resetAmount();
  }, [farm, accountDetails]);

  const handleClickOutside = () => {
    if (prevOptionsExpandedRef.current) {
      setOptionsExpanded(false);
    }
  };

  const stats = useRecoilValue(appStatsSelector);

  const getUnlockScheduleFormatted = (
    milestone: UnlockMilestone,
    currentStats: any
  ) => {
    const remainingEpochs = milestone.epoch
      ? milestone.epoch - currentStats.epoch
      : 0;

    const { unlocksAtDate, unlocksAtText } = calcUnlockDateText({
      epochs: remainingEpochs,
      stats: currentStats,
      hasSteps: false
    });

    return unlocksAtText + ' ' + unlocksAtDate;
  };

  const handleStake = () => {
    if (!amountValue) {
      return;
    }

    const inputTokens = [];
    const nonce =
      farm.farmingToken?.collection === config.LOCKED_UPARK.identifier
        ? selectedLockedToken?.nonce
        : 0;
    const farmingToken = new InputTokenModel({
      identifier: farm.farmingToken.identifier, // The identifier is controller by us in the microservice's config
      nonce: nonce,
      amount: amountValue.toString(),
      collection: farm.farmingToken?.collection
    });

    inputTokens.push(farmingToken);

    const position = farm.positions
      ?.filter((p) => p.farmToken?.decodedAttributes?.unlockEpoch === undefined)
      .find((p) => {
        const requiredIdentifier = lockRewardsValue
          ? config.LOCKED_UPARK.identifier
          : config.UPARK.identifier;
        return p.rewardToken?.identifier === requiredIdentifier && p.farmToken;
      });

    if (position) {
      inputTokens.push(
        new InputTokenModel({
          identifier: position.farmToken?.collection,
          nonce: position.farmToken?.nonce,
          amount: position.farmToken?.balance.toString(),
          collection: farm.farmingToken?.collection
        })
      );
    }

    const args = new StakingArgs({
      tokens: inputTokens,
      lockRewards: lockRewardsValue ?? false
    });

    onStake(args);
    onHide();
  };

  const resetAmount = () => {
    setAmountPercent(0);
    setAmountValue(undefined);
    setInputValue('');
  };

  const LockedTokenOptions = () => (
    <Collapse in={optionsExpanded}>
      <div>
        <div className='d-flex justify-content-end mt-1'>
          <div className='d-flex select-options-container flex-column pl-3 pr-3 pt-2 pb-2'>
            {accountDetails?.lockedTokens
              .filter((lockedToken) => {
                return (
                  lockedToken.identifier !== selectedLockedToken?.identifier
                );
              })
              .map((lockedToken, position) => (
                <div
                  onClick={() => {
                    setSelectedLockedToken(lockedToken);
                    setSelectedTokenBalance(
                      new BigNumber(lockedToken.balance ?? 0)
                    );
                    resetAmount();
                  }}
                  key={position}
                  className='d-flex flex-row align-items-center justify-content-between mb-2 mt-2'
                >
                  <div className='d-flex flex-row align-items-center mr-4'>
                    <div className='d-flex align-items-center flex-grow-1'>
                      <img
                        src={config.LOCKED_UPARK.icon}
                        className='p-0 token-image token-select'
                        style={{ width: '32px', height: '32px' }}
                      />
                      <div className='ml-1 d-flex stake-position-farm-body'>
                        {lockedToken.name} #{lockedToken.nonce}
                        <div>
                          {lockedToken.unlockSchedule.length > 0 &&
                            getUnlockScheduleFormatted(
                              lockedToken.unlockSchedule[0],
                              stats
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='p-3'>
                    {NumberUtils.toDenominatedString(
                      new BigNumber(lockedToken.balance),
                      lockedToken.decimals,
                      lockedToken.decimals
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Collapse>
  );

  const marks = {
    0: '0%',
    25: '25%',
    50: '50%',
    75: '75%',
    100: '100%'
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        resetState();
        onHide();
      }}
    >
      <Modal.Header className='align-items-center'>
        <Modal.Title>Stake in {farm.farmingToken?.name} farm</Modal.Title>
        <span
          className='btn btn-primary btn-xs'
          onClick={() => {
            resetState();
            onHide();
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </Modal.Header>
      <Modal.Body>
        <StakeTokenAmountComponent
          icon={farm.farmingToken?.icon}
          ticker={farm.farmingToken?.name}
          decimals={farm.farmingToken?.decimals}
          amount={inputValue}
          maxAmount={selectedTokenBalance}
          errorMessage={errorMessage}
          onAmountChanged={(amount) => setInputValue(amount)}
        />
        {selectedLockedToken && (
          <div
            className='d-flex flex-row align-items-center mt-3 p-2 pl-3 swap-form-group'
            onClick={() => {
              setOptionsExpanded(!optionsExpanded);
            }}
          >
            <div className='d-flex align-items-center flex-grow-1'>
              <img
                src={config.LOCKED_UPARK.icon}
                className='p-0 token-image token-select'
                style={{ width: '32px', height: '32px' }}
              />
              <div className='ml-1 d-flex stake-position-farm-body'>
                {selectedLockedToken.name} #{selectedLockedToken.nonce}
                <div>
                  {selectedLockedToken.unlockSchedule.length > 0 &&
                    getUnlockScheduleFormatted(
                      selectedLockedToken.unlockSchedule[0],
                      stats
                    )}
                </div>
              </div>
            </div>
            <div className='pt-3 pb-3 pl-3 pr-2'>
              {NumberUtils.toDenominatedString(
                new BigNumber(selectedLockedToken.balance),
                selectedLockedToken.decimals,
                selectedLockedToken.decimals
              )}
              {optionsExpanded ? <ExpandLess /> : <ExpandMore />}
            </div>
          </div>
        )}
        {(accountDetails?.lockedTokens?.length ?? 0) > 1 && (
          <LockedTokenOptions />
        )}
        <div className='d-flex flex-row mt-5 mb-5'>
          <TooltipSlider
            marks={marks}
            step={1}
            disabled={!selectedTokenBalance?.isGreaterThan(new BigNumber(0))}
            onChange={(value) => {
              const input = value as number;
              if (selectedTokenBalance) {
                setInputValue(
                  NumberUtils.toDenominatedString(
                    selectedTokenBalance.multipliedBy(
                      new BigNumber(input / 100)
                    ),
                    farm.farmingToken?.decimals,
                    farm.farmingToken?.decimals
                  )
                );
              }
            }}
            value={amountPercent}
            tipProps={''}
          />
        </div>
        {farm.lockedApr && farm.apr && (
          <div className='mt-4'>
            <input
              style={{ position: 'relative', top: '2px' }}
              className='mr-2'
              id='termsCheckBox'
              type='checkbox'
              checked={lockRewards}
              onChange={() => {
                return setLockRewards(!lockRewards);
              }}
            />
            <LockIcon /> Lock rewards for:
            <div className='stake-apr-container'>
              <span className='apr-rewards-container locked-apr-container mr-1 ml-1'>
                {farm.lockedApr.toFixed()}%
              </span>{' '}
              LKUPARK
              <span className='mx-2'>vs</span>
              <span className='apr-rewards-container apr-container mr-1'>
                {farm.apr.toFixed()}%
              </span>
              UPARK
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-primary btn-sm w-100' onClick={handleStake}>
          Stake
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default StakeModal;
