import React, { useEffect, useState } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BigNumber from 'bignumber.js';
import { Modal } from 'react-bootstrap';
import { Position } from 'apiRequests/Farm';
import { InputTokenModel, UnstakingArgs } from 'apiRequests/staking.args';
import { ReactComponent as Info } from 'assets/img/info.svg';
import { NumberUtils } from 'helpers/number.utils';
import TooltipSlider from '../TooltipSlider';

const getPercentage = (
  inputValue: string,
  position: Position
): { percentage: number; error: string } => {
  if (inputValue.length === 0) {
    return { percentage: 0, error: 'Invalid amount' };
  }

  const amountValueWithDecimals = new BigNumber(inputValue).shiftedBy(
    position.farmToken?.decimals ?? 0
  );

  if (!amountValueWithDecimals?.isPositive()) {
    return { percentage: 0, error: 'Invalid amount' };
  }

  if (
    amountValueWithDecimals?.isGreaterThan(
      position.farmToken?.balance ?? new BigNumber(0)
    )
  ) {
    return { percentage: 100, error: 'Insufficient fonds' };
  }

  if (
    amountValueWithDecimals &&
    position.farmToken?.balance?.isGreaterThan(new BigNumber(0))
  ) {
    const percent = amountValueWithDecimals
      .multipliedBy(new BigNumber(100))
      .dividedBy(position.farmToken?.balance)
      .toNumber();
    return { percentage: percent, error: '' };
  }

  return { percentage: 0, error: '' };
};

interface UnstakeModalProps {
  show: boolean;
  onHide: () => void;
  onUnstake: (args: UnstakingArgs) => void;
  position: Position | undefined;
}

const UnstakeModal = ({
  show,
  onHide,
  onUnstake,
  position
}: UnstakeModalProps) => {
  const [inputValue, setInputValue] = useState('');
  const [amountPercent, setAmountPercent] = useState(0);
  const [stakeValueReceived, setStakeValueReceived] = useState<
    BigNumber | undefined
  >(undefined);
  const [earnedValueReceived, setEarnedValueReceived] = useState<
    BigNumber | undefined
  >(undefined);
  const [errorMessage, setErrorMessage] = useState('');

  const handleUnstake = () => {
    if (
      stakeValueReceived &&
      earnedValueReceived &&
      errorMessage.length === 0 &&
      position
    ) {
      const inputTokens = [];

      const farmToken = new InputTokenModel({
        identifier: position.farmToken?.collection,
        nonce: position.farmToken?.nonce,
        amount: position.farmToken?.balance.toString()
      });

      inputTokens.push(farmToken);

      const args = new UnstakingArgs({
        tokens: inputTokens,
        value: new BigNumber(inputValue).shiftedBy(
          position.farmToken?.decimals ?? 6
        )
      });

      onUnstake(args);
      onHide();
    }
  };

  const setPercentage = (percent: number) => {
    if (!isNaN(percent)) {
      setAmountPercent(percent);
      setReceivedValues(percent);
    }
  };

  useEffect(() => {
    if (!position) {
      return;
    }

    const { percentage, error } = getPercentage(inputValue, position);
    setPercentage(percentage);
    setErrorMessage(error);
  }, [inputValue]);

  useEffect(() => {
    //Reset amount percent when the position changes (new modal shown)
    setInputValue('');
    setPercentage(0);
  }, [position]);

  const setReceivedValues = (percent: number) => {
    const lockedValue = position?.farmToken?.balance ?? new BigNumber(0);
    const earnedValue = position?.rewardToken?.balance ?? new BigNumber(0);

    const floatPercent = new BigNumber(percent / 100);

    const _earnedValueReceived = !earnedValue
      ? undefined
      : floatPercent.multipliedBy(earnedValue);

    const _stakeValueReceived = !lockedValue
      ? undefined
      : floatPercent.multipliedBy(lockedValue);

    setEarnedValueReceived(_earnedValueReceived);
    setStakeValueReceived(_stakeValueReceived);
  };

  const marks = {
    0: '0%',
    25: '25%',
    50: '50%',
    75: '75%',
    100: '100%'
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header className='align-items-center'>
        <Modal.Title>Withdraw</Modal.Title>
        <span className='btn btn-primary btn-xs' onClick={onHide}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </Modal.Header>
      <Modal.Body>
        {NumberUtils.toDenominatedString(
          position?.farmToken?.balance ?? new BigNumber(0),
          position?.farmToken?.decimals,
          position?.farmToken?.decimals
        )}{' '}
        {position?.farmToken?.name} staked
        <div className='d-flex flex-row form-group swap-form-group swap-form-field mt-4'>
          <div className='d-flex flex-column input-group pl-3 pr-3'>
            <input
              type='number'
              className='form-control input-amount input-stake'
              placeholder='Amount'
              onChange={(event) => {
                setInputValue(event.target.value);
              }}
              required
              step='any'
              value={inputValue}
            />
          </div>
          <button
            style={{ height: '21px', fontSize: '10px' }}
            onClick={() => {
              position &&
                setInputValue(
                  NumberUtils.toDenominatedString(
                    position?.farmToken?.balance ?? new BigNumber(0),
                    position?.farmToken?.decimals,
                    position?.farmToken?.decimals
                  )
                );
            }}
            className='btn btn-sm btn-primary mr-2 align-self-center p-0 pl-2 pr-2'
          >
            MAX
          </button>
        </div>
        {errorMessage && (
          <div className='validation-message mt-2 ml-3 flex-grow-1'>
            {errorMessage}
          </div>
        )}
        <div className='d-flex flex-row my-5'>
          <TooltipSlider
            marks={marks}
            step={1}
            onChange={(value) => {
              const input = value as number;
              if (position) {
                setInputValue(
                  NumberUtils.toDenominatedString(
                    position?.farmToken?.balance?.multipliedBy(
                      new BigNumber(input / 100)
                    ) ?? new BigNumber(0),
                    position?.farmToken?.decimals,
                    position?.farmToken?.decimals
                  )
                );
              }
            }}
            value={amountPercent}
            tipProps={''}
          />
        </div>
        <span>You will receive:</span>
        <div className='d-flex flex-column mt-2 py-1'>
          <div className='d-flex flex-row align-items-center p-2'>
            <div className='d-flex flex-grow-1 align-items-center'>
              <img
                src={position?.farmToken?.icon}
                className='p-0 token-image token-select'
                style={{ width: '32px', height: '32px' }}
              />
              {position?.farmToken?.name}
            </div>
            {inputValue.length > 0 ? inputValue : '0'}
          </div>
          <div className='d-flex flex-row align-items-center p-2'>
            <div className='d-flex flex-grow-1 align-items-center'>
              <img
                src={position?.rewardToken?.icon}
                className='p-0 token-image token-select'
                style={{ width: '32px', height: '32px' }}
              />
              {position?.rewardToken?.name}
            </div>
            {!earnedValueReceived
              ? '-'
              : NumberUtils.toDenominatedString(
                  earnedValueReceived,
                  position?.rewardToken?.decimals,
                  position?.rewardToken?.decimals
                )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex flex-column'>
          <div className='d-flex flex-row px-3 py-2 mb-3 align-items-center border border-primary rounded-pill'>
            <Info className='mr-2' />
            <div className='d-flex flex-wrap mr-2'>
              <ul className='mb-0 pl-4'>
                <li className='mb-2'>
                  There is a 10 days unbonding period when you unstake. You will
                  be able to withdraw your funds only after that period.
                </li>
                <li className='mb-2'>
                  Stake that is unbonding will not generate rewards.
                </li>
              </ul>
            </div>
          </div>
          <button
            className='btn btn-primary btn-sm w-100'
            onClick={handleUnstake}
          >
            Unstake
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default UnstakeModal;
