import BigNumber from 'bignumber.js';
import { Farm } from 'apiRequests/Farm';

export const getStakeValueFromInput = (
  inputValue: string,
  farm: Farm,
  selectedTokenBalance: BigNumber
): {
  amount: BigNumber | undefined;
  percentage: number;
  error: string;
} => {
  if (inputValue.length === 0) {
    return { amount: undefined, percentage: 0, error: 'Invalid amount' };
  }

  const amountValueWithDecimals = new BigNumber(inputValue).shiftedBy(
    farm.farmingToken?.decimals ?? 0
  );

  if (amountValueWithDecimals?.isLessThanOrEqualTo(new BigNumber(0))) {
    return { amount: undefined, percentage: 0, error: 'Invalid amount' };
  }

  if (amountValueWithDecimals?.isGreaterThan(selectedTokenBalance)) {
    return {
      amount: undefined,
      percentage: 100,
      error: 'Insufficient fonds'
    };
  }

  if (amountValueWithDecimals) {
    const percent = amountValueWithDecimals
      .multipliedBy(new BigNumber(100))
      .dividedBy(selectedTokenBalance)
      .toNumber();
    return {
      amount: amountValueWithDecimals,
      percentage: percent,
      error: ''
    };
  }

  return { amount: undefined, percentage: 0, error: 'Invalid amount' };
};
