import React, { useEffect, useState } from 'react';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil';
import { Farm } from 'apiRequests/Farm';
import Loader from 'components/Loader';
import PageHeader from 'components/PageHeader';
import StakeForm from 'components/StakeForm';
import { farmsSelector } from 'recoil/selectors/farmsSelector';

const Stake = () => {
  const { address } = useGetAccountInfo();
  const farmsLoadable = useRecoilValueLoadable(farmsSelector(address));
  const refreshFarms = useRecoilRefresher_UNSTABLE(farmsSelector(address));
  const [isLoading, setIsLoading] = useState(false);

  const [farms, setFarms] = useState<Farm[]>();

  useEffect(() => {
    const timer = setInterval(() => refreshFarms(), 6000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    switch (farmsLoadable.state) {
      case 'hasValue':
        setFarms(farmsLoadable.contents);
        if (isLoading) {
          setIsLoading(false);
        }
        break;
      case 'loading':
        setIsLoading(!farms);
        break;
    }
  }, [farmsLoadable]);

  return (
    <div className='d-flex flex-fill flex-column align-items-center container'>
      <PageHeader
        title='Stake *UPARK*'
        description='Stake UPARK / LKUPARK to earn more tokens'
      />
      <div className='row w-100'>
        <div className='col-12 mx-auto'>
          {isLoading ? (
            <Loader />
          ) : (
            farms?.map((farm, position) => (
              <StakeForm key={position} farm={farm} />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Stake;
