import { selector } from 'recoil';
import * as apiRequests from 'apiRequests';
import { AppState } from 'apiRequests/AppState';

export const appStateSelector = selector<AppState>({
  key: 'appStateSelector',
  get: async () => {
    try {
      console.debug('select appStateSelector');

      const appState = await apiRequests.getAppState();
      return appState;
    } catch (error) {
      throw error;
    }
  }
});
