import React from 'react';
import { Navbar as BsNavbar } from 'react-bootstrap';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import AccountBtn from 'components/AccountBtn';
import ActionOrConnect from 'components/ActionOrConnect';

const Navbar = () => {
  return (
    <BsNavbar className='d-flex flex-column'>
      <div
        className='d-flex align-items-stretch justify-content-between flex-nowrap flex-grow-1'
        style={{ width: '100%', height: '5rem' }}
      >
        <div className='d-flex align-items-center navbar-first'>
          <a
            {...{
              target: '_blank'
            }}
            className=' navbar-brand '
            href='https://upark.network'
          >
            <Logo />
          </a>
        </div>

        <div className='d-flex align-items-center justify-content-end navbar-last'>
          <ActionOrConnect className='btn-sm'>
            <AccountBtn />
          </ActionOrConnect>
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-center flex-grow-1'>
        {/* <Nav justify variant='pills'>
          <NavItem>
            <NavLink className='nav-link' to={routeNames.home} end>
              Buy
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className='nav-link' to={routeNames.home} end>
              Stake
            </NavLink>
          </NavItem>
        </Nav> */}
      </div>
    </BsNavbar>
  );
};

export default Navbar;
