import { selectorFamily } from 'recoil';
import * as apiRequests from 'apiRequests';
import { Farm } from 'apiRequests/Farm';

export const farmsSelector = selectorFamily<
  Farm[] | undefined,
  string | undefined
>({
  key: 'farmsSelector',
  get: (address: string | undefined) => async () => {
    try {
      console.debug('select farmsSelector');

      const farms = await apiRequests.getFarms(address);
      return farms;
    } catch (error) {
      throw error;
    }
  }
});
