import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import LoadingPage from 'pages/LoadingPage';
import App from './App';
import './assets/css/clash-grotesk.css';

ReactDOM.render(
  <>
    <RecoilRoot>
      <React.Suspense fallback={<LoadingPage />}>
        <App />
      </React.Suspense>
    </RecoilRoot>
  </>,
  document.getElementById('root')
);
