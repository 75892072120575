import React from 'react';
import BigNumber from 'bignumber.js';
import { NumberUtils } from 'helpers/number.utils';

interface StakeTokenAmountProps {
  ticker: string;
  icon: string;
  decimals: number;
  amount: string;
  maxAmount: BigNumber | undefined;
  errorMessage: string;
  onAmountChanged: (amount: string) => void;
}

const StakeTokenAmountComponent = ({
  ticker,
  icon,
  decimals,
  amount,
  maxAmount,
  errorMessage,
  onAmountChanged
}: StakeTokenAmountProps) => {
  return (
    <div>
      <div className='d-flex flex-row form-group swap-form-group swap-form-field'>
        <div className='d-flex flex-column input-group pl-3 pr-3'>
          <input
            type='number'
            className='form-control input-amount input-stake'
            placeholder='Amount'
            onChange={(event) => {
              onAmountChanged(event.target.value);
            }}
            required
            min='0'
            step='any'
            value={amount}
            disabled={
              !maxAmount || maxAmount?.isLessThanOrEqualTo(new BigNumber(0))
            }
          />
        </div>
        <button
          hidden={
            !maxAmount || maxAmount?.isLessThanOrEqualTo(new BigNumber(0))
          }
          style={{ height: '21px', fontSize: '10px' }}
          onClick={(event) => {
            event.preventDefault();
            if (maxAmount) {
              onAmountChanged(
                NumberUtils.toDenominatedString(maxAmount, decimals, decimals)
              );
            }
          }}
          className='btn btn-sm btn-primary mr-2 align-self-center p-0 pl-2 pr-2'
        >
          MAX
        </button>
        <div className='d-flex flex-row align-items-center stake-form-select pl-3 pr-3'>
          <div className='d-flex flex-row align-items-center mr-1'>
            <img
              src={icon}
              className='p-0 token-image token-select'
              style={{ width: '32px', height: '32px' }}
            />
            {ticker}
          </div>
        </div>
      </div>
      <div className='d-flex flex-row'>
        {errorMessage && (
          <div className='validation-message mt-2 ml-3 flex-grow-1'>
            {errorMessage}
          </div>
        )}
        {maxAmount && (
          <div className='d-flex justify-content-end mt-2 mr-3 flex-grow-1'>
            Balance:{' '}
            {NumberUtils.toDenominatedString(maxAmount, decimals, decimals)}
          </div>
        )}
      </div>
    </div>
  );
};

export default StakeTokenAmountComponent;
