import React from 'react';
import BigNumber from 'bignumber.js';
import { ReactComponent as ExpandLess } from 'assets/img/expand_less.svg';
import { ReactComponent as ExpandMore } from 'assets/img/expand_more.svg';
import { NumberUtils } from 'helpers/number.utils';

type TokenProps = {
  icon: string;
  name: string;
  balance: BigNumber;
  decimals: number;
  ticker: string;
  isLockedToken: boolean;
  lockedTokensLength: number;
  lockedTokensShown: boolean;
};

const Token = ({
  icon,
  name,
  balance,
  decimals,
  ticker,
  isLockedToken,
  lockedTokensLength,
  lockedTokensShown
}: TokenProps) => {
  return (
    <div className='d-flex flex-row align-items-center'>
      <div className='token-image p-0'>
        <img
          src={icon}
          className='token-symbol p-0'
          style={{ width: '35px', height: '35px' }}
        />
      </div>
      <div className='d-flex align-items-center'>
        <div>{name}</div>
        {isLockedToken &&
          lockedTokensLength > 0 &&
          (lockedTokensShown ? <ExpandLess /> : <ExpandMore />)}
      </div>

      <div className='d-flex flex-grow-1 justify-content-end'>
        {NumberUtils.toDenominatedString(new BigNumber(balance), decimals, 4)}{' '}
        {ticker}
      </div>
    </div>
  );
};
export default Token;
