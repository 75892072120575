import moment from 'moment';
import { selector } from 'recoil';
import * as apiRequests from 'apiRequests';

interface StatsType {
  epoch: number;
  refreshRate: number;
  roundsPassed: number;
  roundsPerEpoch: number;
}

export const appStatsSelector = selector<any>({
  key: 'appStatsSelector',
  get: async () => {
    try {
      console.debug('select appStatsSelector');
      const data = await apiRequests.getStats();

      const stats: StatsType = data;
      const secPerRound = stats.refreshRate / 1000;

      const secondsUntilNextEpoch =
        secPerRound * (stats.roundsPerEpoch - stats.roundsPassed);

      const nextEpochDate: any = moment()
        .utc()
        .add(secondsUntilNextEpoch, 'seconds')
        .format();

      return {
        epoch: stats.epoch,
        nextEpochDate,
        epochDurationSec: secPerRound * stats.roundsPerEpoch,
        refreshRate: stats.refreshRate,
        roundsPerEpoch: stats.roundsPerEpoch
      };
    } catch (error) {
      throw error;
    }
  }
});
