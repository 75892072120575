/* eslint-disable @typescript-eslint/no-inferrable-types */
import BigNumber from 'bignumber.js';

export class AccountDetails {
  public egldBalance: BigNumber = new BigNumber(0);
  public uparkBalance: BigNumber = new BigNumber(0);
  public lockedUparkBalance: BigNumber = new BigNumber(0);
  public lockedTokens: LockedToken[] = [];
  public farmTokens: LockedToken[] = [];

  constructor(init?: Partial<AccountDetails>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): AccountDetails {
    return new AccountDetails({
      egldBalance: new BigNumber(response.egldBalance),
      uparkBalance: new BigNumber(response.uparkBalance),
      lockedUparkBalance: new BigNumber(response.lockedUparkBalance),
      lockedTokens: response.lockedTokens.map((lockedToken: any) =>
        LockedToken.fromResponse(lockedToken)
      ),
      farmTokens: response.farmTokens
    });
  }
}

export class LockedToken {
  public identifier: string = '';
  public collection: string = '';
  public nonce: number = 0;
  public name: string = '';
  public balance: string = '0';
  public decimals: number = 0;
  public ticker: string = '';
  public icon: string = '';
  public unlockSchedule: UnlockMilestone[] = [];
  public assets?: any;

  constructor(init?: Partial<LockedToken>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): LockedToken {
    return new LockedToken({
      ...response,
      icon: response.assets?.svgUrl ?? ''
    });
  }
}

export class UnlockMilestone {
  public epoch: number = 0;
  public percent: number = 0;

  constructor(init?: Partial<UnlockMilestone>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): UnlockMilestone {
    return new UnlockMilestone({
      epoch: response.epoch,
      percent: response.percent
    });
  }
}
