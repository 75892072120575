export class AppState {
  state: 'ACTIVE' | 'MAINTENANCE' = 'ACTIVE';

  constructor(init?: Partial<AppState>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): AppState {
    return new AppState({
      state: response.state
    });
  }
}
