import * as React from 'react';
import Loader from 'components/Loader';

const LoadingPage = () => {
  return (
    <div className='d-flex flex-column flex-fill wrapper'>
      <main className='d-flex flex-column flex-grow-1'>
        <div className='d-flex flex-fill align-items-center justify-content-center container text-center'>
          <Loader />
        </div>
      </main>
    </div>
  );
};

export default LoadingPage;
