import { Transaction } from '@multiversx/sdk-core/out';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import config from 'config';
import { NumberUtils } from 'helpers/number.utils';
import { AccountDetails } from './AccountDetails';
import { AppState } from './AppState';
import { ContractConfig } from './ContractConfig';
import { Farm, Position } from './Farm';
import { SaleProgress } from './SaleProgress';
import { SaleState } from './SaleState';
import { SoldTokens } from './SoldTokens';
import {
  InputTokenModel,
  StakingArgs,
  TransactionArgs,
  UnstakingArgs
} from './staking.args';

export const getAccountBalance = async (
  address: string,
  tokenIdentifier: string
): Promise<BigNumber> => {
  try {
    if (address === '' || address === undefined) {
      return new BigNumber(0);
    }

    const { data } = await axios.get(
      `${config.apiAddress}/accounts/${address}/tokens/${tokenIdentifier}`
    );
    return new BigNumber(data.balance);
  } catch (error) {
    console.error('getAccountBalance error: ', error);
    return new BigNumber(0);
  }
};

export const logError = async (args: any) => {
  try {
    await axios.post(`${config.apiAddress}/log`, args, {});
  } catch (error: any) {
    console.log('logError:', error);
  }
};

export const isAddressWhitelisted = async (
  address: string
): Promise<boolean> => {
  try {
    const { data } = await axios.get(
      `${config.apiAddress}/accounts/${address}/whitelist`
    );
    return data.whitelisted;
  } catch (err) {
    console.error('isAddressWhitelisted', err);
    return false;
  }
};

const getSoldTokens = async (): Promise<SoldTokens> => {
  try {
    const { data } = await axios.get(
      `${config.apiAddress}/contracts/sold-tokens`
    );
    return SoldTokens.fromResponse(data);
  } catch (err) {
    console.error('getSoldTokens', err);
    return new SoldTokens();
  }
};

export const getSaleProgress = async (): Promise<SaleProgress> => {
  try {
    const data = await getSoldTokens();

    const tokensAlreadySold = new BigNumber(data.soldTokens).toNumber();
    const tokensToBeSold = new BigNumber(data.tokensToBeSold).toNumber();
    let newPercentage = 0;
    if (tokensToBeSold > 0) {
      newPercentage = (tokensAlreadySold / tokensToBeSold) * 100;
    }
    if (newPercentage > 100) {
      newPercentage = 100;
    }

    return new SaleProgress({
      soldTokens: NumberUtils.toDenominatedString(
        new BigNumber(data.soldTokens),
        config.LOCKED_UPARK.decimals,
        4
      ),
      tokensToBeSold: NumberUtils.toDenominatedString(
        new BigNumber(data.tokensToBeSold),
        config.LOCKED_UPARK.decimals,
        4
      ),
      percentage: newPercentage
    });
  } catch (error) {
    console.error('getSaleProgress error: ', error);
    throw error;
  }
};

export const getAppState = async (): Promise<AppState> => {
  try {
    const { data } = await axios.get(`${config.apiAddress}/dapp`);
    return AppState.fromResponse(data);
  } catch (err) {
    console.error('getAppState error: ', err);
    throw err;
  }
};

export const getSaleState = async (): Promise<SaleState> => {
  try {
    const { data } = await axios.get(
      `${config.apiAddress}/contracts/sale-state`
    );
    return SaleState.fromResponse(data);
  } catch (err) {
    console.error('getSaleState error: ', err);
    throw err;
  }
};

export const getContractConfig = async (): Promise<ContractConfig> => {
  try {
    const { data } = await axios.get(`${config.apiAddress}/contracts/status`);
    return ContractConfig.fromResponse(data);
  } catch (error) {
    console.error('getContractConfig error: ', error);
    throw error;
  }
};

export const getAddressBuys = async (address: string): Promise<BigNumber> => {
  try {
    const { data } = await axios.get(
      `${config.apiAddress}/accounts/${address}/buys`
    );
    return data.buys;
  } catch (err) {
    console.error('getAddressBuys error: ', err);
    return new BigNumber(0);
  }
};

export const getAccountDetails = async (
  address: string
): Promise<AccountDetails> => {
  try {
    const { data } = await axios.get(
      `${config.apiAddress}/accounts/${address}/details`
    );
    return AccountDetails.fromResponse(data);
  } catch (error) {
    console.error('getAccountDetails error: ', error);
    throw error;
  }
};

export const getStats = async (): Promise<any> => {
  try {
    const { data } = await axios.get(`${config.apiAddress}/stats`);
    return data;
  } catch (error) {
    console.error('getStats error: ', error);
    throw error;
  }
};

export const getFarms = async (address?: string): Promise<Farm[]> => {
  try {
    const { data } = await axios.get(
      `${config.apiAddress}/staking/farms?address=${address}&vmQuery=false`
    );
    return data.map((farm: any) => {
      return Farm.fromResponse(farm);
    });
  } catch (error) {
    console.error('getFarms error: ', error);
    throw error;
  }
};

export const getStakeTransaction = async (
  address: string,
  args: StakingArgs
): Promise<Transaction> => {
  return await getTransaction(address, args, 'stake');
};

export const getUnstakeTransaction = async (
  address: string,
  args: UnstakingArgs
): Promise<Transaction> => {
  return await getTransaction(address, args, 'unstake');
};

export const getUnlockTokenTransaction = async (
  address: string,
  token: InputTokenModel
): Promise<Transaction> => {
  return await getTransaction(
    address,
    new TransactionArgs({ tokens: [token] }),
    'unlock'
  );
};

export const getMergeTokenTransaction = async (
  address: string,
  tokens: InputTokenModel[]
): Promise<Transaction> => {
  return await getTransaction(
    address,
    new TransactionArgs({ tokens }),
    'merge'
  );
};

export const getUnbondTransaction = async (
  address: string,
  position: Position
): Promise<Transaction> => {
  const args = new StakingArgs({
    tokens: [
      new InputTokenModel({
        identifier: position.farmToken?.collection,
        nonce: position.farmToken?.nonce,
        amount: position.farmToken?.balance.toString()
      })
    ]
  });
  return await getTransaction(address, args, 'unbond');
};

export const getReinvestTransactions = async (
  address: string,
  args: StakingArgs
): Promise<Transaction[]> => {
  const transactions = await Promise.all(
    args.tokens.map(async (token) => {
      const tx = await getTransaction(
        address,
        new StakingArgs({ tokens: [token] }),
        'reinvest'
      );
      return tx;
    })
  );
  return transactions;
};

export const getHarvestTransactions = async (
  address: string,
  args: StakingArgs
): Promise<Transaction[]> => {
  const transactions = await Promise.all(
    args.tokens.map(async (token) => {
      const tx = await getTransaction(
        address,
        new StakingArgs({ tokens: [token] }),
        'harvest'
      );
      return tx;
    })
  );
  return transactions;
};

export const getTransaction = async (
  address: string,
  args: TransactionArgs,
  method: string
): Promise<Transaction> => {
  try {
    const { data } = await axios.post(
      `${config.apiAddress}/staking/${method}-transaction/${address}`,
      args
    );
    return data as Transaction;
  } catch (error) {
    console.error('getTransaction error: ', error);
    throw error;
  }
};
