import BigNumber from 'bignumber.js';
import EgldIcon from 'assets/img/egld-token-logo.png';
import LKUparkIcon from 'assets/img/lkupark-token-logo.png';
import UparkIcon from 'assets/img/upark-token-logo.png';

class Config {
  public UPDATE_REFRESH_RATE = 60 * 1000;
  public MINIMUM_AMOUNT = 850;
  public dAppName = 'uPark';
  public chainID = '1';
  public environment = 'mainnet';
  public SWAP_CONTRACT_ADDRESS =
    'erd1qqqqqqqqqqqqqpgqqfm677vpmshwydpg8tpduaj4dct5skunlw3srtztn5';
  public VESTING_CONTRACT_ADDRESS =
    'erd1qqqqqqqqqqqqqpgqv7yywsf0n07xw7vt892rh37xvue7l3lrlw3s542e3z';
  public apiAddress = 'https://upark-api-gc77j.ondigitalocean.app';
  public dappAddress = 'https://dapp.upark.city';
  public gatewayAddress = 'https://gateway.elrond.com';
  public walletConnectV2ProjectId = '57c8d914e5dd53399d8f13a92a24e545';
  public UPARK = {
    name: 'Upark',
    identifier: 'UPARK-982dd6',
    icon: UparkIcon,
    ticker: 'UPARK',
    decimals: 6
  };
  public LOCKED_UPARK = {
    name: 'LockedUPARK',
    identifier: 'LKUPARK-34c8ea',
    icon: LKUparkIcon,
    ticker: 'LKUPARK',
    decimals: 6
  };
  public EGLD = {
    name: 'MultiversX eGold',
    identifier: 'EGLD',
    icon: EgldIcon,
    ticker: 'EGLD',
    decimals: 18
  };

  public params = {
    price: new BigNumber(22222),
    buyMinLimit: new BigNumber(11111000000),
    buyMaxLimit: new BigNumber(66666000000),
    addresses: [
      'erd18eer0un6jwfherwvedrg5n2r7eaj2zvcwjq8w2z4sxxk9w2h786s73j4sr'
    ]
  };
}

export default new Config();
