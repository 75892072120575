import React from 'react';
import Footer from './Footer';
import Navbar from './Navbar';

const Layout = ({
  children,
  showNavbar = true
}: {
  children: React.ReactNode;
  showNavbar?: boolean;
}) => {
  // const { search } = useLocation();

  return (
    <div className='d-flex flex-column flex-fill wrapper'>
      {showNavbar && <Navbar />}
      <main className='d-flex flex-column flex-grow-1'>
        {/* <AuthenticatedRoutesWrapper
          routes={routes}
          unlockRoute={`${routeNames.home}${search}`}
        > */}
        {children}
        {/* </AuthenticatedRoutesWrapper> */}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
