import BigNumber from 'bignumber.js';
import { UnlockMilestone } from './AccountDetails';

export class Farm {
  addresses: FarmAddress = new FarmAddress();
  farmingToken: StakeTokenModel = new StakeTokenModel();
  farmTotalSupply: BigNumber = new BigNumber(0);
  apr?: number = 0;
  lockedApr?: number = 0;
  accumulatedRewards: BigNumber = new BigNumber(0);
  accumulatedStakings: BigNumber = new BigNumber(0);
  positions: Position[] = [];

  constructor(init?: Partial<Farm>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): Farm {
    const positions = response.positions.map((position: any) => {
      return Position.fromResponse(position);
    });

    return new Farm({
      addresses: FarmAddress.fromResponse(response.addresses),
      farmingToken: StakeTokenModel.fromResponse(response.farmingToken),
      farmTotalSupply: new BigNumber(response.farmTotalSupply),
      apr: response.apr,
      lockedApr: response.lockedApr,
      accumulatedRewards: new BigNumber(response.accumulatedRewards),
      accumulatedStakings: new BigNumber(response.accumulatedStakings),
      positions: positions
    });
  }
}

export class Position {
  farmToken?: StakeMetaEsdtModel;
  rewardToken?: StakeTokenModel;
  unlockingSchedule: UnlockMilestone[] = [];
  reinvestedAmount: BigNumber = new BigNumber(0);

  constructor(init?: Partial<Position>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): Position {
    return new Position({
      farmToken: StakeMetaEsdtModel.fromResponse(response.farmToken),
      rewardToken: StakeTokenModel.fromResponse(response.rewardToken),
      unlockingSchedule:
        response.unlockingSchedule?.map((schedule: any) => {
          return UnlockMilestone.fromResponse(schedule);
        }) ?? [],
      reinvestedAmount: new BigNumber(response.reinvestedAmount ?? 0)
    });
  }
}
export class StakeTokenModel {
  identifier = '';
  name = '';
  decimals = 0;
  icon = '';
  balance? = new BigNumber(0);
  collection = '';

  constructor(init?: Partial<StakeTokenModel>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): StakeTokenModel {
    return new StakeTokenModel({
      name: response.name,
      icon: response.icon,
      identifier: response.identifier,
      decimals: response.decimals,
      collection: response.collection,
      balance: new BigNumber(response.balance)
    });
  }
}
export class StakeMetaEsdtModel {
  identifier = '';
  collection = '';
  name = '';
  decimals = 0;
  icon = '';
  balance: BigNumber = new BigNumber(0);
  nonce = 0;
  decodedAttributes?: StakeMetaEsdtDecodedAttributes;

  constructor(init?: Partial<StakeMetaEsdtModel>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): StakeMetaEsdtModel {
    return new StakeMetaEsdtModel({
      name: response.name,
      icon: response.assets?.svgUrl ?? '',
      collection: response.collection,
      identifier: response.identifier,
      decimals: response.decimals,
      balance: new BigNumber(response.balance),
      nonce: response.nonce,
      decodedAttributes: StakeMetaEsdtDecodedAttributes.fromResponse(
        response.decodedAttributes
      )
    });
  }
}
export class StakeMetaEsdtDecodedAttributes {
  rewardPerShare?: BigNumber;
  compoundedReward?: BigNumber;
  currentFarmAmount?: BigNumber;
  unlockEpoch?: number;

  constructor(init?: Partial<StakeMetaEsdtDecodedAttributes>) {
    Object.assign(this, init);
  }

  static fromResponse(response?: any): StakeMetaEsdtDecodedAttributes {
    return new StakeMetaEsdtDecodedAttributes({
      rewardPerShare: response?.rewardPerShare,
      compoundedReward: response?.compoundedReward,
      currentFarmAmount: response?.currentFarmAmount,
      unlockEpoch: response?.unlockEpoch
    });
  }
}

export class FarmAddress {
  unlockedRewardsAddress = '';
  lockedRewardsAddress = '';

  constructor(init?: Partial<FarmAddress>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): FarmAddress {
    return new FarmAddress({
      unlockedRewardsAddress: response.unlockedRewardsAddress,
      lockedRewardsAddress: response.lockedRewardsAddress
    });
  }
}
