import * as React from 'react';
import { useLocation } from 'react-router-dom';
import PageHeader from 'components/PageHeader';

const PageNotFound = () => {
  const { pathname } = useLocation();
  return (
    <div className='d-flex flex-fill align-items-center container'>
      <PageHeader title='Page *not found*' description={pathname} />
    </div>
  );
};

export default PageNotFound;
