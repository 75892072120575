import * as React from 'react';
import PageHeader from 'components/PageHeader';

const MaintenancePage = () => {
  return (
    <div className='d-flex flex-fill align-items-center container'>
      {/* <PageHeader
        title='Under *maintenance*'
        subtitle='Coming back *soon*'
        description='Funds are safe.'
      /> */}
      <PageHeader title='Staking will be enabled *soon*' />
    </div>
  );
};

export default MaintenancePage;
