import { selectorFamily } from 'recoil';
import * as apiRequests from 'apiRequests';
import { AccountDetails } from 'apiRequests/AccountDetails';

export const accountDetailsSelector = selectorFamily<
  AccountDetails | undefined,
  string | undefined
>({
  key: 'accountDetailsSelector',
  get: (address: string | undefined) => async () => {
    if (address === undefined || address === '') {
      return undefined;
    }

    try {
      console.debug('select accountDetailsSelector');
      const account = await apiRequests.getAccountDetails(address);
      return account;
    } catch (error) {
      throw error;
    }
  }
});
