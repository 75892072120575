import React from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Loader = () => (
  <div className='loading-container'>
    <FontAwesomeIcon icon={faSpinner} className='icon mb-2' size='2x' />
    <span>Loading..</span>
  </div>
);

export default Loader;
