import React from 'react';
import BigNumber from 'bignumber.js';
import { LockedToken } from 'apiRequests/AccountDetails';
import Token from '../Token';

const FarmTokensList = ({ farmTokens }: { farmTokens: LockedToken[] }) => {
  return (
    <>
      <p className='mt-4'>Farm tokens:</p>
      {farmTokens.map((farmToken, key) => (
        <div key={key} className='list-group-item bg-transparent p-0 mt-3'>
          <Token
            icon={farmToken.assets?.svgUrl ?? ''}
            name={farmToken.name + ' #' + farmToken.nonce}
            balance={new BigNumber(farmToken.balance)}
            decimals={farmToken.decimals}
            ticker={farmToken.ticker}
            isLockedToken={false}
            lockedTokensLength={0}
            lockedTokensShown={false}
          />
        </div>
      ))}
    </>
  );
};

export default FarmTokensList;
