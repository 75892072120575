import BigNumber from 'bignumber.js';

export class NumberUtils {
  static toDenominated(amount: BigNumber, decimals = 18): BigNumber {
    const denominatedValue = amount.shiftedBy(-decimals);
    return denominatedValue;
  }

  static toDenominatedString(
    amount: BigNumber,
    decimals = 18,
    fixed = 18
  ): string {
    let denominatedValue = NumberUtils.toDenominated(amount, decimals).toFixed(
      fixed,
      BigNumber.ROUND_DOWN
    );
    if (denominatedValue.includes('.')) {
      denominatedValue = denominatedValue
        .replace(/0+$/g, '')
        .replace(/\.$/g, '');
    }

    return denominatedValue;
  }

  static toFixed(amount: string, decimals = 2): string {
    return new BigNumber(amount).toFixed(decimals);
  }

  static numberDecode(encoded: string): BigNumber {
    const hex = Buffer.from(encoded, 'base64').toString('hex');
    return new BigNumber(hex, 16);
  }
}
