import BigNumber from 'bignumber.js';

export class TransactionArgs {
  tokens: InputTokenModel[] = [];

  constructor(init?: Partial<TransactionArgs>) {
    Object.assign(this, init);
  }
}
export class StakingArgs extends TransactionArgs {
  lockRewards = false;

  constructor(init?: Partial<StakingArgs>) {
    super(init);
    Object.assign(this, init);
  }
}

export class UnstakingArgs extends TransactionArgs {
  value = new BigNumber(0);

  constructor(init?: Partial<UnstakingArgs>) {
    super(init);
    Object.assign(this, init);
  }
}

export class InputTokenModel {
  identifier = '';
  nonce = 0;
  amount = '0';
  collection = '';
  attributes?: string;

  constructor(init?: Partial<InputTokenModel>) {
    Object.assign(this, init);
  }
}
