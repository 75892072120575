import { UnlockMilestone } from 'apiRequests/AccountDetails';
import { calcUnlockDateText } from 'helpers/epochToDateHelpers';

export const formatUnlockSchedule = (
  unlockSchedule: UnlockMilestone[],
  stats: any
): {
  percent: number;
  unlocksAtDate: string;
  unlocksAtText: string;
  shouldDisplayUnlockButton: boolean;
}[] => {
  const mergedUnlockSchedule = mergeUnlockSchedule([...unlockSchedule], stats);
  return mergedUnlockSchedule.map((milestone: UnlockMilestone) => {
    const remainingEpochs = getRemainingEpochs(stats, milestone.epoch);

    const shouldDisplayUnlockButton = remainingEpochs <= 0;

    const { unlocksAtDate, unlocksAtText } = calcUnlockDateText({
      epochs: remainingEpochs,
      stats,
      hasSteps: false
    });

    return {
      percent: milestone.percent,
      unlocksAtDate,
      unlocksAtText,
      shouldDisplayUnlockButton
    };
  });
};

const mergeUnlockSchedule = (
  unlockSchedule: UnlockMilestone[],
  stats: any
): UnlockMilestone[] => {
  let mergedMilestone = 0;

  const remainingMilestones = unlockSchedule.filter((milestone) => {
    const remainingEpochs = getRemainingEpochs(stats, milestone.epoch);
    if (remainingEpochs <= 0) {
      mergedMilestone += milestone.percent;
      return false;
    }
    return true;
  });

  if (mergedMilestone > 0) {
    remainingMilestones.unshift({
      epoch: stats.epoch,
      percent: mergedMilestone
    });
  }

  return remainingMilestones;
};

export const getRemainingEpochs = (stats: any, epoch?: number): number => {
  return epoch !== undefined && stats.epoch !== undefined
    ? epoch - stats.epoch
    : 0;
};
