import React from 'react';
import { useCheckVersion } from 'useCheckVersion';

const UpdateNotification = () => {
  const updateAvailable = useCheckVersion();

  return updateAvailable ? (
    <div className='d-flex justify-content-between align-items-center px-3 py-3 px-md-4 bg-primary text-dark font-weight-bold'>
      A new version of the Dapp is available.
      <a
        href='/#'
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          window.location.reload();
        }}
        className='ml-1 text-black font-weight-bold'
      >
        <span className=' text-dark'>Reload</span>
      </a>
    </div>
  ) : (
    <></>
  );
};

export default UpdateNotification;
