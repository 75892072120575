import React, { useEffect, useMemo, useState } from 'react';
import { faTimes, faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useGetAccountInfo,
  useGetNetworkConfig,
  useTrackTransactionStatus
} from '@multiversx/sdk-dapp/hooks';
import { logout } from '@multiversx/sdk-dapp/utils';
import BigNumber from 'bignumber.js';
import { Collapse, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';
import { LockedToken } from 'apiRequests/AccountDetails';
import * as transactionRequests from 'apiRequests/transactions';
import { ReactComponent as Info } from 'assets/img/info.svg';
import Spinner from 'components/Spinner';
import useLocalStorage from 'components/SwapForm/helpers/local';
import config from 'config';
import { NumberUtils } from 'helpers/number.utils';
import { accountDetailsSelector } from 'recoil/selectors/accountDetailsSelector';
import { appStatsSelector } from 'recoil/selectors/appStatsSelector';
import FarmTokensList from './FarmTokensList';
import { formatUnlockSchedule } from './helpers';
import Token from './Token';
import UnlockSchedule from './UnlockSchedule';

interface AccountModalProps {
  show: boolean;
  onHide: () => void;
}

const AccountModal = ({ show, onHide }: AccountModalProps) => {
  const { network } = useGetNetworkConfig();
  const { address } = useGetAccountInfo();

  const accountDetails = useRecoilValue(accountDetailsSelector(address));
  const [lockedTokensShown, setLockedTokensShown] = useState(false);

  const [merge, setMerge] = useState(false);
  const [tokensToMerge, setTokensToMerge] = useState<Record<string, boolean>>(
    {}
  );

  const refreshAccountDetails = useRecoilRefresher_UNSTABLE(
    accountDetailsSelector(address)
  );

  const lockedTokensLength = accountDetails?.lockedTokens.length ?? 0;

  const handleUpdateBalances = () => {
    setSessionId(null);
    refreshAccountDetails();
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const renderTooltip = (unlockSchedule, props) => (
    <Tooltip {...props}>
      <UnlockSchedule unlockSchedule={unlockSchedule} />
    </Tooltip>
  );

  const handleMergeCheckbox = (checked: boolean, identifier: string) => {
    setTokensToMerge({
      ...tokensToMerge,
      [identifier]: checked
    });
  };

  const formattedTokensToMerge = useMemo(
    () =>
      Object.entries(tokensToMerge)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_key, value]) => value)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(([key, _value]) => key),
    [tokensToMerge]
  );

  const LockedTokenItem = ({ lockedToken }: { lockedToken: LockedToken }) => {
    return (
      <li className='list-group-item bg-dark p-0 mb-3'>
        {merge ? (
          <div className='d-flex flex-row align-items-center'>
            <input
              className='mr-2'
              type='checkbox'
              id={lockedToken.identifier}
              checked={tokensToMerge[lockedToken.identifier] ?? false}
              onChange={(event: any) =>
                handleMergeCheckbox(
                  event.target.checked,
                  lockedToken.identifier
                )
              }
            />
            <label style={{ width: '100%' }} htmlFor={lockedToken.identifier}>
              <LockedTokenComponent lockedToken={lockedToken} />
            </label>
          </div>
        ) : (
          <LockedTokenComponent lockedToken={lockedToken} />
        )}
      </li>
    );
  };

  const [sessionId, setSessionId] = useLocalStorage<string | null>(
    'unlockSessionId',
    null
  );

  const trackTransactionStatus = useTrackTransactionStatus({
    transactionId: sessionId,
    onSuccess: handleUpdateBalances,
    onFail: handleUpdateBalances,
    onTimedOut: handleUpdateBalances,
    onCancelled: handleUpdateBalances
  });

  const [isLoading, setIsLoading] = useState(trackTransactionStatus.isPending);

  useEffect(() => {
    setIsLoading(trackTransactionStatus.isPending);
  }, [accountDetails]);

  const handleConfirmMerge = async () => {
    const filteredTokens =
      accountDetails?.lockedTokens.filter((lockedToken) =>
        formattedTokensToMerge?.includes(lockedToken.identifier)
      ) ?? [];

    const tokens: any[] = filteredTokens.map((token: any) => {
      return {
        tokenID: token.collection,
        nonce: token.nonce ?? 0,
        amount: token.balance
      };
    });

    const response = await transactionRequests.mergeTokens(address, tokens);

    setTokensToMerge({});
    setMerge(false);
    setIsLoading(response.success);
    setSessionId(response.sessionId);
  };

  const MergeButton = () => {
    const SwowTooltip = () => (
      <>
        {merge && (
          <OverlayTrigger
            placement='top'
            overlay={(props) => (
              <Tooltip {...props}>
                <p>
                  Minimum 2 and maximum 5 {config.LOCKED_UPARK.ticker} can be
                  merged.
                </p>
                <p>
                  You will be asked to sign <strong>1 transaction.</strong>
                </p>
                <p>
                  Unlock schedule will be averaged out when the tokens are
                  merged.
                </p>
              </Tooltip>
            )}
          >
            <Info
              style={{ width: '20px', height: '20px', marginLeft: '5px' }}
            />
          </OverlayTrigger>
        )}
      </>
    );

    if (
      merge &&
      2 <= formattedTokensToMerge.length &&
      formattedTokensToMerge.length <= 4
    ) {
      return (
        <>
          <button
            className='btn btn-xs btn-primary'
            onClick={handleConfirmMerge}
          >
            Confirm merge
          </button>
          <SwowTooltip />
        </>
      );
    }

    if (merge) {
      return (
        <>
          <button className='btn btn-xs' onClick={() => setMerge(false)}>
            Cancel
          </button>
          <SwowTooltip />
        </>
      );
    }

    return (
      <>
        <button className='btn btn-xs' onClick={() => setMerge(true)}>
          Merge {config.LOCKED_UPARK.ticker}
        </button>
        <SwowTooltip />
      </>
    );
  };

  const LockedTokensList = () => (
    <Collapse in={lockedTokensShown}>
      <div id='locked-tokens-list' style={{ textAlign: 'center' }}>
        <ul className='list-group mt-3 ml-4'>
          {accountDetails?.lockedTokens.map((lockedToken, key) => (
            <LockedTokenItem key={key} lockedToken={lockedToken} />
          ))}
        </ul>
        {lockedTokensLength > 0 && <MergeButton />}
      </div>
    </Collapse>
  );

  const LockedTokenComponent = ({ lockedToken }: any) => {
    const stats = useRecoilValue(appStatsSelector);

    const unlockScheduleFormatted = formatUnlockSchedule(
      lockedToken.unlockSchedule,
      stats
    );
    const shouldDisplayUnlockButton = unlockScheduleFormatted.some(
      (schedule) => schedule.shouldDisplayUnlockButton === true
    );

    const handleUnlock = async () => {
      const response = await transactionRequests.unlockToken(address, {
        tokenID: lockedToken.collection,
        nonce: lockedToken.nonce ?? 0,
        amount: lockedToken.balance
      });

      setIsLoading(response.success);
      setSessionId(response.sessionId);
    };

    return (
      <div className='d-flex flex-row align-items-center'>
        <div className='token-image p-0'>
          <img
            src={config.LOCKED_UPARK.icon}
            className='token-symbol p-0'
            style={{ width: '35px', height: '35px' }}
          />
        </div>
        <div className='locked-token-info d-flex align-items-center'>
          <div className='d-flex flex-row '>
            {lockedToken.name} #{lockedToken.nonce}
            <div className='ml-2'>
              <OverlayTrigger
                placement='bottom'
                overlay={(props) =>
                  renderTooltip(unlockScheduleFormatted, props)
                }
              >
                <Info style={{ width: '20px', height: '20px' }} />
              </OverlayTrigger>
            </div>
          </div>
          {shouldDisplayUnlockButton && !merge && (
            <button className='unlock-btn btn btn-xs' onClick={handleUnlock}>
              Unlock
            </button>
          )}
        </div>
        <div className='locked-token-value d-flex flex-grow-1 justify-content-end'>
          <div>
            {NumberUtils.toDenominatedString(
              new BigNumber(lockedToken.balance),
              lockedToken.decimals,
              4
            )}{' '}
          </div>
          <div>{config.LOCKED_UPARK.ticker}</div>
        </div>
      </div>
    );
  };

  const handleLogout = () => {
    logout(`${window.location.origin}/`);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header className='align-items-center'>
        <Modal.Title>Account details</Modal.Title>
        <span className='btn btn-primary btn-xs' onClick={onHide}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </Modal.Header>
      <Modal.Body>
        <p>Your wallet:</p>
        <a
          className='break-word'
          href={`${network.explorerAddress}/address/${address}`}
          rel='noreferrer'
          target='_blank'
        >
          {address}
        </a>
        {isLoading ? (
          <div
            className='d-flex flex-row justify-content-center mt-4'
            style={{ width: '100%', height: '100px' }}
          >
            <Spinner />
          </div>
        ) : (
          <>
            <ul className='list-group'>
              <li className='list-group-item bg-transparent p-0 mt-3'>
                <Token
                  icon={config.EGLD.icon}
                  name={config.EGLD.name}
                  balance={accountDetails?.egldBalance ?? new BigNumber(0)}
                  decimals={config.EGLD.decimals}
                  ticker={config.EGLD.ticker}
                  isLockedToken={false}
                  lockedTokensLength={lockedTokensLength}
                  lockedTokensShown={lockedTokensShown}
                />
              </li>

              <li className='list-group-item bg-transparent p-0 mt-3'>
                <Token
                  icon={config.UPARK.icon}
                  name={config.UPARK.name}
                  balance={accountDetails?.uparkBalance ?? new BigNumber(0)}
                  decimals={config.UPARK.decimals}
                  ticker={config.UPARK.ticker}
                  isLockedToken={false}
                  lockedTokensLength={lockedTokensLength}
                  lockedTokensShown={lockedTokensShown}
                />
              </li>

              <li
                className='list-group-item bg-transparent p-0 mt-3'
                onClick={() => {
                  if (lockedTokensLength > 0) {
                    setLockedTokensShown(!lockedTokensShown);
                  }
                }}
                aria-controls='locked-tokens-list'
                aria-expanded={lockedTokensShown}
              >
                <Token
                  icon={config.LOCKED_UPARK.icon}
                  name={config.LOCKED_UPARK.name}
                  balance={
                    accountDetails?.lockedUparkBalance ?? new BigNumber(0)
                  }
                  decimals={config.LOCKED_UPARK.decimals}
                  ticker={config.LOCKED_UPARK.ticker}
                  isLockedToken={true}
                  lockedTokensLength={lockedTokensLength}
                  lockedTokensShown={lockedTokensShown}
                />
              </li>
            </ul>
            <LockedTokensList />
            <FarmTokensList farmTokens={accountDetails?.farmTokens ?? []} />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-primary btn-xs w-100' onClick={handleLogout}>
          <FontAwesomeIcon icon={faDoorOpen} className='mr-2' />
          Disconnect
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AccountModal;
