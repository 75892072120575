import React, { useMemo, useState } from 'react';
import { Transaction } from '@multiversx/sdk-core/out/transaction';
import {
  useGetAccountInfo,
  useTrackTransactionStatus
} from '@multiversx/sdk-dapp/hooks';
import { FormatAmount } from '@multiversx/sdk-dapp/UI';
import BigNumber from 'bignumber.js';
import { Collapse, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';
import * as apiRequests from 'apiRequests';
import { Farm, Position } from 'apiRequests/Farm';
import { StakingArgs, UnstakingArgs } from 'apiRequests/staking.args';
import * as transactionRequests from 'apiRequests/transactions';
import { ReactComponent as ExpandLess } from 'assets/img/expand_less.svg';
import { ReactComponent as ExpandMore } from 'assets/img/expand_more.svg';
import { ReactComponent as Info } from 'assets/img/info.svg';
import { ReactComponent as LockIcon } from 'assets/img/lock_icon.svg';
import { formatUnlockSchedule } from 'components/AccountModal/helpers';
import UnlockSchedule from 'components/AccountModal/UnlockSchedule';
import ActionOrConnect from 'components/ActionOrConnect';
import Loader from 'components/Loader';
import useLocalStorage from 'components/SwapForm/helpers/local';
import config from 'config';
import { calcUnlockDateText } from 'helpers/epochToDateHelpers';
import { NumberUtils } from 'helpers/number.utils';
import { accountDetailsSelector } from 'recoil/selectors/accountDetailsSelector';
import { appStatsSelector } from 'recoil/selectors/appStatsSelector';
import { farmsSelector } from 'recoil/selectors/farmsSelector';
import ClaimRewardsModal, { ClaimType } from './ClaimRewards';
import StakeModal from './StakeModal';
import UnstakeModal from './UnstakeModal';

interface StakeFormProps {
  farm: Farm;
}

interface PositionProps {
  position: Position;
}

const StakeForm = ({ farm }: StakeFormProps) => {
  const { address } = useGetAccountInfo();
  const isLoggedIn = Boolean(address);

  const [showStakeModal, setShowStakeModal] = useState({
    show: false,
    farm: new Farm()
  });
  const [positionsExpanded, setPositionsExpanded] = useState(false);
  const [claimRewardsModal, setClaimRewardsModal] = useState<{
    show: boolean;
    type: ClaimType;
    positions: Position[];
  }>({
    show: false,
    type: ClaimType.HARVEST,
    positions: []
  });
  const [withdrawModal, setWithdrawModal] = useState({
    show: false,
    position: new Position()
  });
  const [sessionId, setSessionId] = useLocalStorage<string | null>(
    'unlockSessionId',
    null
  );
  const refreshAccountDetails = useRecoilRefresher_UNSTABLE(
    accountDetailsSelector(address)
  );
  const refreshFarms = useRecoilRefresher_UNSTABLE(farmsSelector(address));

  const handleUpdateInfo = () => {
    setSessionId(null);
    refreshAccountDetails();
    refreshFarms();
  };

  const processStakeRequest = async (args: StakingArgs) => {
    try {
      console.log(args);
      const transaction = await apiRequests.getStakeTransaction(address, args);

      processTransactions([transaction], 'stake');
    } catch (error) {
      console.log('processStakeRequest error', error);
    }
  };

  const processUnbondRequest = async (position: Position) => {
    try {
      const transaction = await apiRequests.getUnbondTransaction(
        address,
        position
      );

      processTransactions([transaction], 'unbond');
    } catch (error) {
      console.log('processUnbondRequest error', error);
    }
  };

  const processUnstakeRequest = async (args: UnstakingArgs) => {
    try {
      const transaction = await apiRequests.getUnstakeTransaction(
        address,
        args
      );

      processTransactions([transaction], 'unstake');
    } catch (error) {
      console.log('processUnstakeRequest error', error);
    }
  };

  const processTransactions = async (
    transactions: Transaction[],
    method: string
  ) => {
    try {
      const response = await transactionRequests.sendAndSignTransactions(
        transactions,
        method
      );

      setSessionId(response.sessionId);
    } catch (error) {
      console.log('processTransaction error', error);
    }
  };

  const trackTransactionStatus = useTrackTransactionStatus({
    transactionId: sessionId,
    onSuccess: handleUpdateInfo,
    onFail: handleUpdateInfo,
    onTimedOut: handleUpdateInfo,
    onCancelled: handleUpdateInfo
  });

  const isLoading = useMemo(
    () => trackTransactionStatus.isPending,
    [trackTransactionStatus]
  );

  const filteredPositions = farm.positions
    .filter(
      (position) =>
        position.farmToken?.decodedAttributes?.unlockEpoch === undefined
    )
    .filter((position) => {
      const rewardBalance = position.rewardToken?.balance ?? new BigNumber(0);
      const minimumAmount = new BigNumber(config.MINIMUM_AMOUNT).shiftedBy(
        position.rewardToken?.decimals ?? 6
      );

      return minimumAmount.isLessThan(rewardBalance);
    });

  const StakePositionsList = () => (
    <Collapse in={positionsExpanded}>
      <div id='positions-list' className='card bg-dark swap-form stake-footer'>
        <div className='card-body'>
          {isLoading ? (
            <div className='text-center'>
              <Loader />
            </div>
          ) : isLoggedIn && farm.positions && farm?.positions?.length > 0 ? (
            <ul className='list-group w-100'>
              {farm?.positions?.map((position, key) => (
                <div key={key}>
                  <StakePosition position={position} />
                  {key < (farm?.positions?.length ?? 0) - 1 && <Divider />}
                </div>
              ))}
            </ul>
          ) : (
            <div className='text-center'>
              Stake now to earn {farm.farmingToken?.name}.
            </div>
          )}
        </div>
      </div>
    </Collapse>
  );

  const Divider = () => <div className='stake-divider' />;

  const StakePosition = ({ position }: PositionProps) => {
    const isDisabled = useMemo(() => {
      const rewardBalance = position.rewardToken?.balance ?? new BigNumber(0);
      const minimumAmount = new BigNumber(config.MINIMUM_AMOUNT).shiftedBy(
        position.rewardToken?.decimals ?? 6
      );

      return minimumAmount.isGreaterThan(rewardBalance);
    }, []);

    const isUnbond = position.farmToken?.decodedAttributes?.unlockEpoch;

    const stats = useRecoilValue(appStatsSelector);

    const { unlocksAtDate, unlocksAtText } = calcUnlockDateText({
      epochs: position.farmToken?.decodedAttributes?.unlockEpoch ?? 0,
      stats,
      hasSteps: false
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const renderTooltip = (sum, unlockSchedule, props) => (
      <Tooltip {...props}>
        <div className='mb-2'>
          Reinvested{' '}
          {NumberUtils.toDenominatedString(
            sum ?? new BigNumber(0),
            position.rewardToken?.decimals,
            position.rewardToken?.decimals
          )}{' '}
          {position.rewardToken?.name}
        </div>
        <UnlockSchedule unlockSchedule={unlockSchedule} />
      </Tooltip>
    );

    return (
      <div className='d-flex justify-content-between pb-3 pt-3'>
        <div className='d-flex justify-content-between stake-position-card w-100'>
          <div className='d-flex flex-row align-items-center mr-2 w-50'>
            <img
              src={position.farmToken?.icon}
              className='p-0 token-image'
              style={{ width: '32px', height: '32px' }}
            />
            <div className='ml-2 d-flex stake-position-farm-body'>
              <div className='d-flex flex-row'>
                {position.farmToken?.name} staked
                {position.unlockingSchedule.length > 0 && (
                  <div className='ml-2'>
                    <OverlayTrigger
                      placement='top'
                      overlay={(props) =>
                        renderTooltip(
                          position.reinvestedAmount,
                          formatUnlockSchedule(
                            position.unlockingSchedule,
                            stats
                          ),
                          props
                        )
                      }
                    >
                      <Info style={{ width: '20px', height: '20px' }} />
                    </OverlayTrigger>
                  </div>
                )}
              </div>
              <div>
                {NumberUtils.toDenominatedString(
                  position.farmToken?.balance ?? new BigNumber(0),
                  position.farmToken?.decimals ?? farm.farmingToken.decimals,
                  4
                )}
              </div>
            </div>
          </div>
          <div className='d-flex flex-row align-items-center mr-2 w-50'>
            {isUnbond === undefined && (
              <>
                <img
                  src={position.rewardToken?.icon}
                  className='token-image p-0'
                  style={{ width: '32px', height: '32px' }}
                />
                <OverlayTrigger
                  placement='top'
                  overlay={
                    <Tooltip id='button-tooltip-2'>
                      {NumberUtils.toDenominatedString(
                        position.rewardToken?.balance ?? new BigNumber(0),
                        position.rewardToken?.decimals,
                        position.rewardToken?.decimals
                      )}
                    </Tooltip>
                  }
                >
                  <div className='ml-2 d-flex stake-position-farm-body'>
                    {position.rewardToken?.name} earned
                    <div>
                      {NumberUtils.toDenominatedString(
                        position.rewardToken?.balance ?? new BigNumber(0),
                        position.rewardToken?.decimals,
                        4
                      )}
                    </div>
                  </div>
                </OverlayTrigger>
              </>
            )}
          </div>
        </div>
        {isUnbond !== undefined ? (
          <div className='d-flex stake-position-buttons'>
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip>{unlocksAtText + ' ' + unlocksAtDate}</Tooltip>}
            >
              <div>
                <button
                  disabled={isUnbond > 0}
                  className='btn btn-sm btn-primary btn-stake-position-action'
                  onClick={() => processUnbondRequest(position)}
                >
                  Unbond
                </button>
              </div>
            </OverlayTrigger>
          </div>
        ) : (
          <div className='d-flex stake-position-buttons'>
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip>
                  Individual rewards can be reinvested with a minimum of{' '}
                  {config.MINIMUM_AMOUNT} {position.rewardToken?.name}
                </Tooltip>
              }
            >
              <div>
                <button
                  disabled={isDisabled}
                  className='btn btn-sm btn-primary btn-stake-position-action'
                  onClick={(event) =>
                    showClaimRewardsModal(event, ClaimType.REINVEST, [position])
                  }
                >
                  Reinvest
                </button>
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip>
                  Individual rewards can be harvested with a minimum of{' '}
                  {config.MINIMUM_AMOUNT} {position.rewardToken?.name}
                </Tooltip>
              }
            >
              <div>
                <button
                  disabled={isDisabled}
                  className='btn btn-sm btn-primary btn-stake-position-action'
                  onClick={(event) =>
                    showClaimRewardsModal(event, ClaimType.HARVEST, [position])
                  }
                >
                  Harvest
                </button>
              </div>
            </OverlayTrigger>
            <button
              className='btn btn-sm btn-primary btn-stake-position-action'
              onClick={() =>
                setWithdrawModal({
                  show: true,
                  position: position
                })
              }
            >
              Withdraw
            </button>
          </div>
        )}
      </div>
    );
  };

  const showClaimRewardsModal = (
    event: React.MouseEvent<HTMLElement>,
    type: ClaimType,
    positions: Position[]
  ) => {
    event.stopPropagation();
    setClaimRewardsModal({
      show: true,
      type,
      positions
    });
  };

  return (
    <>
      <div className='mb-4'>
        <div
          className='card bg-dark swap-form stake-body d-flex flex-row'
          onClick={(event) => {
            setPositionsExpanded(!positionsExpanded);
            event.stopPropagation();
          }}
          aria-controls='positions-list'
          aria-expanded={positionsExpanded}
        >
          <div className='pr-2 card-body d-flex justify-content-between stake-card-main'>
            <div className='d-flex flex-row align-items-center stake-farm-header'>
              <div className='p-0'>
                <img
                  src={farm.farmingToken?.icon}
                  className='p-0 token-image'
                  style={{ width: '48px', height: '48px' }}
                />
              </div>
              <div className='ml-3'>
                <div className='font-weight-bold'>
                  Stake {farm.farmingToken?.name}
                </div>
                <div className='dotted-underline'>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip id='button-tooltip-2'>
                        Total Value Staked
                      </Tooltip>
                    }
                  >
                    <div>
                      <FormatAmount
                        value={farm.farmTotalSupply.toFixed()}
                        decimals={farm.farmingToken.decimals}
                        showLabel={false}
                        digits={0}
                      />
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
              <div className='d-flex align-items-center arrow-column'>
                {positionsExpanded ? <ExpandLess /> : <ExpandMore />}
              </div>
            </div>
            <div className='d-flex stake-farm-body'>
              APR
              <div>
                {farm.apr && <>{Math.floor(farm.apr).toString()} % /</>}{' '}
                {farm.lockedApr && (
                  <>
                    <LockIcon />
                    {Math.floor(farm.lockedApr).toString()}%
                  </>
                )}
              </div>
            </div>
            <div className='d-flex stake-farm-body'>
              My Staked {farm.farmingToken?.name}
              <div>
                <FormatAmount
                  value={farm.accumulatedStakings.toFixed()}
                  decimals={farm.farmingToken?.decimals ?? 6}
                  showLabel={false}
                />
              </div>
            </div>
            <div className='d-flex stake-farm-body'>
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip id='button-tooltip-2'>
                    {NumberUtils.toDenominatedString(
                      farm.accumulatedRewards,
                      farm.farmingToken?.decimals,
                      farm.farmingToken?.decimals
                    )}
                  </Tooltip>
                }
              >
                <div>
                  My Rewards
                  <div>
                    <FormatAmount
                      value={farm.accumulatedRewards.toFixed()}
                      decimals={farm.farmingToken?.decimals ?? 6}
                      showLabel={false}
                    />
                  </div>
                </div>
              </OverlayTrigger>
            </div>
            <div className='d-flex stake-buttons'>
              <ActionOrConnect className='btn-sm btn-stake-action'>
                <div className='d-flex stake-buttons'>
                  <button
                    disabled={filteredPositions.length === 0}
                    type='submit'
                    className='btn btn-sm btn-primary btn-stake-action'
                    onClick={(event) =>
                      showClaimRewardsModal(
                        event,
                        ClaimType.REINVEST,
                        filteredPositions
                      )
                    }
                  >
                    Reinvest all
                  </button>
                  <button
                    disabled={filteredPositions.length === 0}
                    type='submit'
                    className='btn btn-sm btn-primary btn-stake-action'
                    onClick={(event) =>
                      showClaimRewardsModal(
                        event,
                        ClaimType.HARVEST,
                        filteredPositions
                      )
                    }
                  >
                    Harvest all
                  </button>
                  <button
                    type='submit'
                    className='btn btn-sm btn-primary btn-stake-action'
                    onClick={() =>
                      setShowStakeModal({
                        show: true,
                        farm: farm
                      })
                    }
                  >
                    Stake
                  </button>
                </div>
              </ActionOrConnect>
            </div>
          </div>
          <div className='d-flex align-items-center arrow-row'>
            {positionsExpanded ? <ExpandLess /> : <ExpandMore />}
          </div>
        </div>
        <StakePositionsList />
      </div>

      <StakeModal
        show={showStakeModal.show}
        onHide={() =>
          setShowStakeModal({
            show: false,
            farm: new Farm()
          })
        }
        onStake={(args) => {
          processStakeRequest(args);
        }}
        farm={showStakeModal.farm}
      />
      <UnstakeModal
        show={withdrawModal.show}
        onHide={() =>
          setWithdrawModal({
            show: false,
            position: new Position()
          })
        }
        onUnstake={(args) => {
          processUnstakeRequest(args);
        }}
        position={withdrawModal.position}
      />
      <ClaimRewardsModal
        show={claimRewardsModal.show}
        processTransactions={processTransactions}
        onHide={() =>
          setClaimRewardsModal({
            show: false,
            type: ClaimType.HARVEST,
            positions: []
          })
        }
        type={claimRewardsModal.type}
        positions={claimRewardsModal.positions}
      />
    </>
  );
};

export default StakeForm;
