import * as React from 'react';

interface PageHeaderProps {
  title: string;
  subtitle?: string;
  description?: string;
}

const PageHeader = ({ title, subtitle, description }: PageHeaderProps) => {
  const regex = /(\*.*?\*)/g;

  const parseTitle = (text: string) => {
    return text.split(regex).map((str, i) =>
      str.search(regex) !== -1 ? (
        <span key={i} className='text-primary'>
          {str.substring(1, str.length - 1)}
        </span>
      ) : (
        str
      )
    );
  };

  const parsedTitle = parseTitle(title);
  const parsedSubtitle = subtitle ? parseTitle(subtitle) : '';

  return (
    <div className='row w-100'>
      <div className='col-12 col-lg-7 mx-auto'>
        <div className='page-header text-center'>
          <h2 className='page-name'>{parsedTitle}</h2>
          {subtitle && <h3 className='page-name'>{parsedSubtitle}</h3>}
          {description && <h3 className='page-description'>{description}</h3>}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
