import config from 'config';
// import Buy from 'pages/Buy';
import Stake from 'pages/Stake';
import withPageTitle from './components/PageTitle';
// import Home from './pages/Home';

export const routeNames = {
  home: '/',
  unlock: '/unlock',
  ledger: '/ledger',
  walletconnect: '/walletconnect',
  stake: '/stake',
  buy: '/buy'
};

const routes: Array<any> = [
  {
    path: routeNames.home,
    title: 'Stake',
    component: Stake
  },
  // {
  //   path: routeNames.buy,
  //   title: 'Buy',
  //   component: Buy
  // },
  {
    path: routeNames.stake,
    title: 'Stake',
    component: Stake
  }
];

const mappedRoutes = routes.map((route) => {
  const title = route.title
    ? `${route.title} • ${config.dAppName}`
    : `${config.dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});

export default mappedRoutes;
