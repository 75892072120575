import React, { useEffect } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import {
  ExtensionLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton,
  WebWalletLoginButton
} from '@multiversx/sdk-dapp/UI';
import { Modal } from 'react-bootstrap';
import { routeNames } from 'routes';

interface ConnectModalProps {
  show: boolean;
  onHide: () => void;
}

const ConnectModal = ({ show, onHide }: ConnectModalProps) => {
  const { address } = useGetAccountInfo();
  const isLoggedIn = Boolean(address);

  useEffect(() => {
    if (isLoggedIn) {
      onHide();
    }
  }, [isLoggedIn]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Connect to a wallet</Modal.Title>
        <span className='btn btn-primary btn-xs' onClick={onHide}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </Modal.Header>
      <Modal.Body className='d-flex flex-column upark-container'>
        <ExtensionLoginButton
          callbackRoute={routeNames.home}
          loginButtonText='MultiversX DeFi Wallet'
        />
        <WalletConnectLoginButton
          callbackRoute={routeNames.home}
          loginButtonText='xPortal App'
          isWalletConnectV2={true}
          modalClassName='upark-container'
        />
        <WebWalletLoginButton
          callbackRoute={routeNames.home}
          loginButtonText='MultiversX Web Wallet'
        />
        <LedgerLoginButton
          callbackRoute={routeNames.home}
          loginButtonText='Ledger'
          modalClassName='upark-container'
        />
      </Modal.Body>
    </Modal>
  );
};

export default ConnectModal;
